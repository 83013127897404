import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import PageInfo from "../components/pageinfo";
import { StdDateTime as DateTime } from "../components/date";
import { Tags } from "../components/tags";
import { AllTags } from "../components/all-tags";
import { SidebarWrapper } from "../components/sidebar-wrapper";
import { parseDate, formatUniversalUTCDate } from "../common/utils";

const PostLink = ({post}) => {
    const {excerpt, frontmatter: {pinned, pinnedExcerpt, title, date, updated, tags}, fields: {slug}} = post;
    return (
        <div className={`blog-list-entry content-box ${pinned ? "pinned" : ""}`}>
            {/*pinned ? <em style={{float: "right", color: "#d3dae0"}}>Pinned Post</em> : null*/}
            <h2>
                <Link to={slug}>
                    {title}
                </Link>
            </h2>
            <div className="tags">
                <em>
                    {pinned ? "Pinned post" : <>Posted <DateTime v={date}/></>}
                    {!pinned && <Tags tags={tags} prefix=" in" />}
                    {updated &&
                        <span className="blog-post-dates"> (updated <DateTime v={updated }/>)</span>
                    }
                </em>
            </div>
            <p className="excerpt">{pinnedExcerpt || excerpt}</p>
        </div>
    );
};

const BlogListPage = ({
    pageContext,
    data: { pinnedPosts, allPosts, tagPosts }
}) => {
    const tag = pageContext ? pageContext.tag : null;
    const title = tag ? `Blog - Posts Tagged '${tag}'` : "Blog";
    const header = tag ? `Posts Tagged '${tag}'` : "All Posts";
    // Order matters here; we want the pinned posts first (in reverse date order; the query does that), then
    // the others (in reverse date order, query does that)
    const posts = allPosts || tagPosts;
    const edges = pinnedPosts ? [...pinnedPosts.edges, ...posts.edges] : posts.edges;
    // const edges = [...pinnedPosts?.edges, ...(allPosts || tagPosts).edges];
    let days = [];
    let current = null;
    for (const { node } of edges) {
        const { pinned } = node.frontmatter;
        const thisDate = node.frontmatter.date;
        const day = pinned ? "Pinned" : formatUniversalUTCDate(parseDate(thisDate));
        if (!current || current.day !== day) {
            current = {
                pinned,
                day,
                date: thisDate,
                posts: []
            };
            days.push(current);
        }
        current.posts.push(node);
    }
    days = days.map(({pinned, date, posts}) => {
        const key = pinned ? "pinned" : date;
        return (
            <div key={key} id={`posts-${key}`}>
                {!pinned &&
                <h1 className="blog-day-header">
                    <DateTime v={date}/>
                </h1>
                }
                {posts.map(post => <PostLink key={post.id} post={post} />)}
            </div>
        );
    });
    return <>
        <PageInfo title={title} />
        <Layout>
            <SidebarWrapper
                contentBox={false}
                main={<>
                    <div className="content-box">
                        <h1 className="blog-list-header">
                            {header}
                        </h1>
                    </div>
                    {days}
                </>}
                side={<>
                    <div>Posts by Tag</div>
                    <div>
                        <AllTags />
                    </div>
                </>}
            />
        </Layout>
    </>;
};

export default BlogListPage;

export const pageQuery = graphql`
    query($withTag: Boolean = false, $tag: String) {
        pinnedPosts: allMarkdownRemark(
            limit: 2000
            filter: { frontmatter: { pinned: { eq: true } } }
            sort: { fields: [frontmatter___pinOrder], order: ASC }
        ) @skip(if: $withTag) {
            edges {
                node {
                    ...PostData
                }
            }
        }
        allPosts: allMarkdownRemark(
            limit: 2000
            filter: {
                frontmatter: {
                    pinned: { ne: true }
                	unlisted: { ne: true }
            	}
            }
            sort: { fields: [frontmatter___date], order: DESC }
        ) @skip(if: $withTag) {
            edges {
                node {
                    ...PostData
                }
            }
        }
        tagPosts: allMarkdownRemark(
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {
                frontmatter: {
                    tags: { in: [$tag] }
                	unlisted: { ne: true }
            	}
            }
        ) @include(if: $withTag) {
            edges {
                node {
                    ...PostData
                }
            }
        }
    }
    fragment PostData on MarkdownRemark {
        id
        excerpt(pruneLength: 250)
        frontmatter {
            date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
            updated(formatString: "YYYY-MM-DDTHH:mm:ssZ")
            pinned
            pinnedExcerpt
            title
            tags
        }
        fields {
            slug
        }
    }
`;
